import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import { getter } from "../../api";
import {
  OpportunityAutocompleteResult,
  OpportunityType,
  OrganizationAutocompleteResult,
} from "../../api/types";
import Avatar from "../Avatar";
import NewModal from "../NewModal";
import { getOpportunityType } from "../Opportunity/OpportunityUtils";
import OpportunityForm from "../OpportunityForm";
import { getDefaultOppType } from "../OpportunityForm/OpportunityFormUtils";
import { dateFormatDDMMMYYYY } from "../Utils/commons";
import Select from "./Select";
import { signalsColor, signalsColorList } from "../utils";

type OpportunitySelectProps = {
  id?: string;
  showAddButton?: boolean;
  ignoredList?: number[];
  value?: OpportunityAutocompleteResult[];
  setValue?: (value: OpportunityAutocompleteResult[]) => void;
  formatOpportunityOption?: (
    value: OpportunityAutocompleteResult,
  ) => JSX.Element;
  fixWidth?: boolean;
  opportunityTypes?: OpportunityType[];
  className?: string;
  placeholder?: string;
  onChange: any;
  isMulti?: boolean;
};

export default function OpportunitySelect({
  showAddButton = true,
  ignoredList = [],
  setValue,
  value,
  formatOpportunityOption,
  fixWidth = false,
  opportunityTypes,
  className,
  placeholder = "Create or Select Opportunities",
  onChange,
  isMulti,
  ...props
}: OpportunitySelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const [defaultOpportunityType, setDefaultOpportunityType] = useState<
    number | undefined
  >();
  const [opportunityOptions, setOpportunityOptions] = useState<
    OpportunityAutocompleteResult[]
  >([]);

  const { data } = useSWR<OpportunityAutocompleteResult[]>(
    `/api/people_map/autocomplete/opportunities?q=${debouncedQuery}&limit=50`,
    getter,
  );

  useEffect(() => {
    if (opportunityTypes) {
      setDefaultOpportunityType(getDefaultOppType(opportunityTypes));
    }
  }, [opportunityTypes]);

  useEffect(() => {
    if (data && data.length > 0) {
      let opportunities = data;
      if (ignoredList?.length) {
        const temp = opportunities.filter(
          (dt) => !ignoredList.includes(dt.value),
        );
        setOpportunityOptions(temp);
      } else {
        setOpportunityOptions(opportunities);
      }
    }
    // eslint-disable-next-line
  }, [data, ignoredList]);

  const defaultFormatOpportunityOption = (
    result: OpportunityAutocompleteResult,
  ) => {
    if (!result) {
      return null;
    }

    const opportunityType = getOpportunityType(
      opportunityTypes || [],
      result.opportunity_type,
    );

    const coloredOpportunities = opportunityType
      ? signalsColor(
          opportunityType.funnel,
          (item) => item.name,
          signalsColorList,
        )
      : [];

    const funnelStage = result.funnel_stage;
    const funnelColor = coloredOpportunities.find(
      (opportunity) => opportunity.name === funnelStage,
    )?.color;

    return (
      <div className="flex h-max items-center hover:cursor-pointer">
        <div
          className={`mr-2 mb-auto h-8 w-3 min-w-[3px] max-w-[3px] rounded-md ${
            result.status === "active"
              ? "bg-green-500"
              : result.status === "closed"
                ? "bg-red-500"
                : result.status === "revisit"
                  ? "bg-yellow-500"
                  : ""
          }`}
        />
        <div className="flex h-max w-full items-center justify-between hover:cursor-pointer">
          <div className="break-word-custom grid h-full w-full items-center text-2xs">
            <div className="flex items-center justify-between">
              <div className="flex">
                {result.organizations.map((org) => (
                  <div className="mr-2" key={org.value}>
                    <Avatar
                      name={org.label}
                      size={40}
                      url={org.image_url}
                      className="hidden shrink-0 rounded-none shadow-md sm:flex"
                    />
                  </div>
                ))}
                <div className="flex items-center hover:text-blue-900">
                  <div className="text-2xs">
                    <div className="text-left font-bold">{result.label}</div>
                    <div className="flex flex-row flex-wrap items-center justify-start space-x-2">
                      <span className="inline-block">
                        {result.opportunity_type}
                      </span>
                      <div
                        className={`text-2xs font-semibold ${funnelColor?.text} ${funnelColor?.bg} rounded-md p-1`}
                      >
                        {result?.funnel_stage}
                      </div>
                      <span className="ml-1 inline-block">
                        {result.created_at && (
                          <span className="flex items-center text-2xs">
                            {/* <CalendarIcon className="h-4 w-4 mr-1" />{" "} */}
                            {dateFormatDDMMMYYYY(result.created_at)}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-2">
                <Avatar
                  name={result.owner?.name}
                  size={26}
                  className="shrink-0"
                  url={result.owner?.image_url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex">
        <Select
          options={opportunityOptions}
          placeholder={placeholder}
          formatOptionLabel={
            formatOpportunityOption
              ? formatOpportunityOption
              : defaultFormatOpportunityOption
          }
          isMulti={isMulti}
          onChange={onChange}
          {...props}
          className={`w-full ${className}`}
          value={value}
          onInputChange={(value) => {
            setQuery(value);
          }}
        />
        <div className="ml-2">
          {showAddButton && (
            <div
              className="mt-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-blue-900"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <PlusIcon className="h-5 w-5 fill-white" />
            </div>
          )}
        </div>
      </div>
      <NewModal
        title="Add New opportunity"
        open={isOpen}
        onClose={setIsOpen}
        showConfirm={true}
      >
        <OpportunityForm
          newOpportunity={(opportunity) => {
            let organizations: OrganizationAutocompleteResult[] | undefined =
              opportunity?.opportunity_organizations.map((org) => {
                return {
                  domain: org.organization_domain || "",
                  image_url: org.organization_logo_url || "",
                  label: org.opportunity_name || "",
                  value: org.id || "",
                };
              });

            const newOpportunity: OpportunityAutocompleteResult = {
              label: opportunity?.name || "",
              value: opportunity?.id || 0,
              opportunity_type: opportunity?.opportunity_type.name || "",
              funnel_stage: opportunity?.funnel_stage.name || "",
              organizations: organizations || [],
            };
            setValue && setValue([...(value || []), newOpportunity]);
          }}
          {...props}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          defaultOpportunityType={defaultOpportunityType}
        />
      </NewModal>
    </div>
  );
}
