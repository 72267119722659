import React, { useState } from "react";
import { avatarColor } from "./utils";

// https://stackoverflow.com/a/70710316
// Modified to return only one letter when there are no spaces.
function getAvatar(name: string) {
  const hasTokens = name.indexOf(" ") !== -1;
  return (
    name.charAt(0) + (hasTokens ? name.charAt(name.lastIndexOf(" ") + 1) : "")
  );
}

type AvatarProps = {
  name?: string;
  size: number;
  className?: string;
  url?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  name = "",
  size,
  className = "",
  url,
}) => {
  const [isBrokenImg, setIsBrokenImg] = useState(false);

  const { bg, text } = avatarColor(name);
  const avatarText = getAvatar(name).toUpperCase();

  const commonProps = {
    className: `grid justify-center items-center text-white rounded-full shrink-0 ${className} ${text} ${bg}`,
    style: {
      width: size,
      height: size,
      fontSize: size / 3,
    },
  };

  if (!url || isBrokenImg) {
    return <div {...commonProps}>{avatarText}</div>;
  } else {
    return (
      <div {...commonProps}>
        <img
          {...commonProps}
          alt="Photo"
          loading="lazy"
          src={url}
          onError={() => {
            setIsBrokenImg(true);
          }}
        />
      </div>
    );
  }
};

export default Avatar;
