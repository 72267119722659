import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ConfirmationModal } from "./ConfirmationModal";

type ModalProps = {
  title?: string;
  subtitle?: string;
  open: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  showConfirm?: boolean;
  expanded?: boolean;
  buttons?: React.ReactNode | React.ReactNode[];
  customClass?: string;
  showExpandButton?: boolean;
  confirmModalTitle?: string;
};

const CLOSE_CONFIRM_MODAL_DELAY = 200;

export const NewModal = ({
  title,
  subtitle,
  open,
  onClose = () => {},
  onSubmit,
  showConfirm = false,
  children,
  expanded = true,
  buttons,
  customClass,
  showExpandButton = true,
  confirmModalTitle = "Are you sure you want to quit this form editing?",
}: PropsWithChildren<ModalProps>) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const isDesktop = useMediaQuery({
    minWidth: 640,
  });

  const closeModal = () => {
    if (showConfirm) {
      setShowConfirmation(true);
    } else {
      onClose(false);
    }
  };

  useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[101]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`relative align-middle ${customClass} max-h-[92vh] transform overflow-auto rounded-lg bg-slate-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all`}
                  style={{
                    width: isDesktop ? (expand ? "80%" : "60%") : "100%",
                  }}
                >
                  <div className="absolute right-5 top-5 flex items-center space-x-1">
                    <div>
                      {showExpandButton && (
                        <button
                          type="button"
                          className="hidden rounded-md text-gray-400 hover:text-gray-500 sm:block"
                          onClick={() => setExpand(!expand)}
                        >
                          {expand ? (
                            <ArrowsPointingInIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowsPointingOutIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      )}
                    </div>
                    <button
                      type="button"
                      className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={closeModal}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    {title && (
                      <Dialog.Title className="flex flex-row items-center text-sm font-semibold leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    )}
                    <div className="mt-2 text-xs">{children}</div>
                    {subtitle && (
                      <div className="mt-2">
                        <p className="text-xs text-gray-500">{subtitle}</p>
                      </div>
                    )}
                  </div>
                  {buttons && (
                    <div className="mt-5 flex flex-row justify-center gap-x-2 sm:mt-4 sm:flex-row-reverse sm:justify-start">
                      {buttons}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {showConfirm && (
        <ConfirmationModal
          isEditingForm={true}
          open={showConfirmation}
          title={confirmModalTitle}
          subtitle="Note that all changes made will not be saved."
          processing={false}
          processingText="Processing"
          submitButtonText="Continue Editing"
          closeText="Discard Changes"
          showCrossButton={true}
          onDiscardChanges={() => {
            setShowConfirmation(false);
          }}
          onClose={() => {
            setShowConfirmation(false);
            setTimeout(() => {
              onClose(false);
            }, CLOSE_CONFIRM_MODAL_DELAY);
          }}
          onSubmit={() => {
            setShowConfirmation(false);
            if (onSubmit) {
              onSubmit();
            }
          }}
        />
      )}
    </>
  );
};

export default NewModal;
