import { FormikErrors, FormikTouched } from "formik";
import { classNames } from "../utils";

type FormTextFieldProps = {
  name: string;
  label: string;
  required: boolean;
  value: string;
  onChange?: (event: any) => void;
  errors:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  isClearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  customClass?: string;
  onBlur?: () => void;
};
const FormTextField = ({
  name,
  required,
  label,
  errors,
  touched,
  isClearable,
  placeholder,
  disabled = false,
  type = "text",
  customClass = "",
  onBlur,
  ...props
}: FormTextFieldProps) => {
  return (
    <div className={`w-full md:w-11/12 ${customClass}`}>
      <div className="flex shrink-0">
        <label
          className="text-xs font-semibold leading-6 text-gray-900"
          htmlFor={name}
        >
          {label}
        </label>
        {required ? (
          <div className="text-sm font-semibold text-red-600">*</div>
        ) : (
          ""
        )}
      </div>
      <div className="relative w-full">
        <input
          className={classNames(
            "border-1 block w-full rounded-md text-xs focus:!border-blue-900 focus:ring-0 active:outline-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500",
            touched && touched !== undefined && errors && errors !== undefined
              ? "border-red-500"
              : touched && !errors
                ? "border-blue-900"
                : "border-gray-300",
          )}
          type={type}
          id={name}
          name={name}
          placeholder={placeholder ? placeholder : ""}
          disabled={disabled}
          onBlur={onBlur && onBlur}
          {...props}
        ></input>
        <div
          className={classNames(
            "flex flex-row justify-start",
            touched && touched !== undefined && errors && errors !== undefined
              ? "text-2xs text-red-600"
              : "hidden",
          )}
        >
          {errors?.toString()}
        </div>
      </div>
    </div>
  );
};

export default FormTextField;
