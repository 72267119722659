import { ErrorMessage, ErrorMessageProps, Field, FieldProps } from "formik";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { GroupAutocompleteResult, Visibility } from "../../api/types";
import GroupSelectAll from "../Select/GroupSelectAll";
import Tooltip from "../Tooltip";
import UserSelect from "../Select/UserSelect";
import { GLOBAL_GROUP_KEY } from "../Utils/constant";
import { classNames } from "../utils";

type VisibilityFieldCustomProps = {
  values: Visibility;
  hideLabel?: boolean;
};

const visibilityOptions = [
  { value: "public", label: "All users" },
  { value: "groups", label: "Specific groups/users" },
  { value: "private", label: "Only me" },
];

export default function VisibilityFieldForOpportunity({
  values,
  hideLabel = false,
}: VisibilityFieldCustomProps) {
  const { data: initialValues } = useSWR<any>(GLOBAL_GROUP_KEY);

  const [selectedGroup, setSelectedGroup] =
    useState<GroupAutocompleteResult[]>();

  useEffect(() => {
    if (initialValues) {
      setSelectedGroup(initialValues);
    }
  }, [initialValues, setSelectedGroup]);

  interface FieldErrorProps extends ErrorMessageProps {}

  const FieldError = ({ name }: FieldErrorProps) => (
    <ErrorMessage name={name}>
      {(error) => <div className="mt-1 text-red-500">{error}</div>}
    </ErrorMessage>
  );

  return (
    <>
      <fieldset className="contents">
        <legend
          className={`mb-1"} mt-5 text-xs font-semibold ${
            hideLabel ? "hidden" : ""
          }`}
        >
          <div className="text-bold mb-2 flex w-full flex-row items-center text-xs">
            Visibility
            <Tooltip
              title="What is Visibility?"
              content="Visibility allows you to have an option of deciding who can view the activity. All user activity by default is only visible to user groups or funds that you belong to."
            />
          </div>
        </legend>
        <div>
          <div className="flex space-x-1 md:space-x-6">
            {visibilityOptions.map((visibilityType) => (
              <div key={visibilityType.value} className="flex items-center">
                <Field
                  id={`visibilityField-${visibilityType.value}`}
                  name="visibility"
                  type="radio"
                  value={visibilityType.value}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor={`visibilityField-${visibilityType.value}`}
                  className="ml-1 block text-2xs text-gray-700 md:ml-3 md:text-xs"
                >
                  {visibilityType.label}
                </label>
              </div>
            ))}
          </div>
          <FieldError name="visibility" />
        </div>
      </fieldset>
      <div
        className={classNames(
          "flex contents flex-row",
          values !== "groups" && "hidden",
        )}
      >
        <div className="">
          <label className="mt-5 mb-2 flex items-center" htmlFor="groupsField">
            <div className="text-xs font-semibold">
              Visible to specific groups/users
            </div>
            <div className="h-4 w-4 text-red-600">*</div>
          </label>
        </div>
        <div className="mb-5 flex flex-col">
          <div className="text-xs">
            <Field name="groups">
              {({
                field,
                form: { setFieldValue, touched, errors },
              }: FieldProps) => {
                touched.groups = true;
                return (
                  <div className="relative mb-2">
                    <GroupSelectAll
                      id="groupsField"
                      {...field}
                      onChange={(newValue: any) => {
                        setFieldValue(field.name, newValue);
                      }}
                      isMulti
                      placeholder="Select Group"
                      className={classNames(
                        "border-1 relative h-fit w-full items-center rounded-md",
                        touched.groups && errors.groups
                          ? "border-1 border border-red-500"
                          : "border-1 border border-blue-900",
                      )}
                    />
                  </div>
                );
              }}
            </Field>
          </div>
          <div className="text-xs">
            <Field name="users">
              {({
                field,
                form: { setFieldValue, touched, errors },
              }: FieldProps) => {
                touched.groups = true;
                return (
                  <div className="relative">
                    <UserSelect
                      id="usersField"
                      {...field}
                      onChange={(newValue) => {
                        setFieldValue(field.name, newValue);
                      }}
                      isMulti
                      placeholder="Select User"
                      className={classNames(
                        "relative h-fit w-full items-center rounded-md",
                        touched.groups && errors.groups
                          ? "border-1 border border-red-500"
                          : "border-1 border border-blue-900",
                      )}
                      isClearable={true}
                    />
                    <div
                      className={classNames(
                        touched.groups && errors.groups
                          ? "text-2xs text-red-600"
                          : "hidden",
                      )}
                    >
                      {errors.groups as any}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
}
