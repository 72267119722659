import {
  CheckIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { groupBy } from "lodash";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { getValChat, updateHistoryTitle } from "../../api";
import { ValChat } from "../../api/types";
import ErrorModal from "../ErrorModal";
import SpinnerCustom from "../SpinnerCustom";
import { parseAxiosError } from "../utils";
import { ShowSnackBar } from "../Utils/supportMessage";

const PAGE_SIZE = 20;

type HistoryViewProps = {
  chatHistory: ValChat[];
  setChatHistory: Dispatch<SetStateAction<ValChat[]>>;
  historyPage: number;
  setHistoryPage: Dispatch<SetStateAction<number>>;
  totalPage: number;
  setTotalPage: Dispatch<SetStateAction<number>>;
  currentConservationId: number | null;
  onItemChanged: (historyId: number) => void;
  onItemDeleted: (historyId: number) => void;
  isMobileMode?: boolean;
  showAskVal: boolean;
};

export const HistoryView = ({
  chatHistory,
  historyPage,
  setHistoryPage,
  totalPage,
  setTotalPage,
  currentConservationId,
  setChatHistory,
  onItemChanged,
  onItemDeleted,
  isMobileMode = false,
  showAskVal,
}: HistoryViewProps) => {
  const [isLoadHisory, setIsLoadHisory] = useState<boolean>(false);
  const popoverRefs = useRef<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [renameHistory, setRenameHistory] = useState<ValChat | null>(null);

  useEffect(() => {
    if (!isMobileMode && showAskVal && chatHistory.length === 0) {
      setIsLoadHisory(true);
      getValChat(historyPage, PAGE_SIZE)
        .then((response: any) => {
          if (response.data) {
            setChatHistory([...chatHistory, ...response.data.items]);
            setHistoryPage(response.data.page);
            setTotalPage(response.data.pages);
          }
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        })
        .finally(() => setIsLoadHisory(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAskVal]);

  const loadMoreHistory = () => {
    if (historyPage < totalPage) {
      const newPage = historyPage + 1;
      setIsLoadHisory(true);
      getValChat(newPage, PAGE_SIZE)
        .then((response: any) => {
          if (response.data) {
            setChatHistory([...chatHistory, ...response.data.items]);
            setTotalPage(response.data.pages);
          }
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        })
        .finally(() => setIsLoadHisory(false));
      setHistoryPage(newPage);
    }
  };

  const classifyHistory = (chatHistory: ValChat[]) => {
    let histories: any[] = [];

    chatHistory.forEach((history) => {
      let difference_In_Time =
        new Date(history.updated_at).getTime() - new Date().getTime();
      const days = Math.abs(
        Math.round(difference_In_Time / (1000 * 3600 * 24)),
      );
      if (days === 0) {
        histories.push({
          ...history,
          period: 0,
        });
      } else if (days < 7) {
        histories.push({
          ...history,
          period: 7,
        });
      } else if (days < 30) {
        histories.push({
          ...history,
          period: 30,
        });
      } else {
        histories.push({
          ...history,
          period: format(new Date(history.updated_at), "MMM yyyy"),
        });
      }
    });
    const groupByHistory = groupBy(histories, "period");
    return groupByHistory;
  };

  const getPeriod = (period: string) => {
    switch (period) {
      case "0":
        return "Today";
      case "7":
        return "Previous 7 Days";
      case "30":
        return "Previous 30 Days";
      default:
        return period;
    }
  };

  const classifyChat =
    chatHistory.length > 0 ? classifyHistory(chatHistory) : {};

  const updateTitle = (historyId: number, newTitle: string) => {
    updateHistoryTitle(historyId, newTitle).then((response) => {
      if (response.data) {
        chatHistory.forEach((chat) => {
          if (chat.id === historyId) {
            chat.last_response = response.data.data.last_response;
          }
        });
        setChatHistory(chatHistory);
        setRenameHistory(null);
        ShowSnackBar("Title is changed!");
      }
    });
  };

  const HistoryTitleUpdateView = ({ history }: { history: ValChat }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [title, setTitle] = useState<string>("");
    useEffect(() => {
      setTitle(history.last_response);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(
            history.last_response.length,
            history.last_response.length,
          );
          inputRef.current.focus();
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renameHistory, inputRef.current]);

    return (
      <div className="flex flex-row items-center rounded-md bg-gray-100">
        <input
          ref={inputRef}
          type="text"
          className="mr-1 h-[32px] w-full rounded-md border-0 border border-gray-300 bg-gray-100 p-0 py-2 px-1 text-xs text-gray-900 focus:outline-none focus:ring-0"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          onKeyDown={(event: any) => {
            if (event.keyCode === 13) {
              event.preventDefault();
              updateTitle(history.id, title);
            }
          }}
        />
        <CheckIcon
          className="mr-0.5 h-5 w-5 cursor-pointer stroke-gray-400 stroke-[4px] hover:stroke-gray-500"
          onClick={() => {
            updateTitle(history.id, title);
          }}
        />
        <XMarkIcon
          className="mr-1 h-5 w-5 cursor-pointer stroke-gray-400 stroke-[4px] hover:stroke-gray-500"
          onClick={() => setRenameHistory(null)}
        />
      </div>
    );
  };

  const HistoryTitle = ({ history }: { history: ValChat }) => {
    return (
      <div
        className={`h-[23px] p-1 line-clamp-1 ${
          currentConservationId === history.id && "font-semibold"
        }`}
      >
        {history.last_response && history.last_response.trim().length > 0
          ? history.last_response
          : "No content"}
      </div>
    );
  };

  return (
    <div className={`relative mx-2 flex h-full flex-col px-1 pt-3`}>
      <div className="text-sm font-semibold">History</div>
      {isLoadHisory ? (
        <SpinnerCustom />
      ) : (
        <div className="mt-2 flex h-full flex-col gap-y-1 overflow-y-auto text-2xs">
          {Object.keys(classifyChat).length > 0 ? (
            <div className="flex flex-col gap-y-2">
              {Object.keys(classifyChat).map((key) => (
                <div key={key}>
                  <div className="text-2xs font-semibold">{getPeriod(key)}</div>
                  <div className="flex flex-col">
                    {classifyChat[key].map((history: ValChat) =>
                      renameHistory && renameHistory.id === history.id ? (
                        <HistoryTitleUpdateView
                          key={history.id}
                          history={renameHistory}
                        />
                      ) : (
                        <div
                          id={`chat-history-item-${history.id}`}
                          key={history.id}
                          className={`group flex flex-row items-center justify-between gap-x-5 rounded hover:cursor-pointer hover:bg-gray-100 hover:px-0.5 hover:text-black ${
                            currentConservationId === history.id &&
                            "bg-blue-100 text-blue-900"
                          }`}
                          onClick={() => {
                            setRenameHistory(null);
                            onItemChanged(history.id);
                          }}
                          onMouseLeave={() => {
                            classifyChat[key].forEach((item) => {
                              if (
                                popoverRefs.current[item.id] &&
                                popoverRefs.current[item.id].ariaExpanded ===
                                  "true"
                              ) {
                                popoverRefs.current[item.id].click();
                              }

                              const pop = document.getElementById(
                                `pop_history_${item.id}`,
                              );
                              if (pop) {
                                if (!pop.classList.contains("hidden")) {
                                  pop.classList.add("hidden");
                                }
                              }
                            });
                          }}
                        >
                          <HistoryTitle history={history} />
                          <div
                            className="h-5 w-5"
                            id={`chat-item-${history.id}-action-button`}
                          >
                            <div
                              ref={(ref: any) => {
                                popoverRefs.current[history.id] = ref;
                              }}
                            >
                              <EllipsisVerticalIcon
                                className={`grey-500 h-5 w-5`}
                                onClick={() => {
                                  const pop = document.getElementById(
                                    `pop_history_${history.id}`,
                                  );
                                  if (pop) {
                                    if (!pop.classList.contains("hidden")) {
                                      pop.classList.add("hidden");
                                    } else {
                                      pop.style.top =
                                        popoverRefs.current[history.id]
                                          .offsetTop +
                                        20 +
                                        "px";
                                      pop.classList.remove("hidden");
                                    }
                                  }
                                }}
                              />
                            </div>
                            <div
                              id={`pop_history_${history.id}`}
                              className="absolute right-1 z-top hidden w-fit rounded-lg border border-gray-200 bg-white p-2"
                            >
                              <div className="flex flex-row gap-x-1">
                                <div
                                  className="h-4 w-4 cursor-pointer"
                                  onClick={(event) => {
                                    setRenameHistory(history);
                                    close();
                                    event.stopPropagation();
                                  }}
                                >
                                  <PencilIcon className="h-4 w-4 stroke-gray-500 hover:stroke-blue-900" />
                                </div>
                                <div
                                  id="delete-ai-chat-button"
                                  className="h-4 w-4 cursor-pointer"
                                  onClick={() => {
                                    onItemDeleted(history.id);
                                    close();
                                  }}
                                >
                                  <TrashIcon className="h-4 w-4 stroke-gray-500 hover:stroke-red-500" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            []
          )}
          {chatHistory.length > 0 && historyPage < totalPage && (
            <div className="mt-2 flex flex-row justify-start">
              <button
                className="btn-primary px-3 py-1"
                disabled={historyPage === totalPage}
                onClick={() => loadMoreHistory()}
              >
                {isLoadHisory ? "Loading" : "Load More"}
              </button>
            </div>
          )}
        </div>
      )}

      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
};
