import Spinner from "./Spinner";

interface ButtonProps {
  buttonStyle: "primary" | "danger" | "plain";
  shape?: "default" | "square";
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  loadingText?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

export const Button = ({
  buttonStyle,
  disabled = false,
  hidden = false,
  shape = "default",
  children,
  className,
  isLoading,
  loadingText,
  buttonRef,
  ...props
}: ButtonProps) => {
  const shapes = {
    primary: `${
      shape === "square" ? "rounded-md" : "rounded-full"
    } shadow-sm px-4 py-2 bg-blue-900 text-2xs font-medium text-white`,
    danger: `${
      shape === "square" ? "rounded-md" : "rounded-full"
    } shadow-sm px-4 py-2 bg-red-500 text-2xs font-medium text-white`,
    plain: "px-4 py-2 text-2xs font-medium",
  };
  return (
    <button
      ref={buttonRef}
      disabled={disabled || isLoading}
      type={`${buttonStyle === "primary" ? "submit" : "button"}`}
      className={`${shapes[buttonStyle]} ${hidden && "hidden"} ${
        disabled && "hover bg-gray-500 opacity-50"
      } ${className} flex flex-row items-center`}
      {...props}
    >
      {isLoading ? (
        <div className="flex flex-row items-center gap-x-2 text-white">
          <div>
            <Spinner className="h-4 w-4 text-white" />
          </div>
          <div>{loadingText}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};
