import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowSmallLeftIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import { Fragment, PropsWithChildren, useState } from "react";
import useSWR from "swr";
import CreateDropDownMenu from "./CreateDropDownMenu";
import QuickSearch from "./QuickSearch";
import MobileSidebar from "./SidebarMobileView";
import SideNavigationBar from "./SideNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import { setNavigation } from "./Utils/commons";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

export type GlobalGroupProps = {
  label: string;
  value: string;
};

const Navigation = ({ children }: PropsWithChildren<{}>) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openQuickSearch, setOpenQuickSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const router = useRouter();
  const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

  const { data: selectedGroup } = useSWR<GlobalGroupProps>(GLOBAL_GROUP_KEY);

  var updatedNavigation = setNavigation("default");

  if (selectedGroup) {
    updatedNavigation = setNavigation(selectedGroup.label);
  }

  const current = updatedNavigation.find(
    (item) =>
      item?.href === router.pathname ||
      item.prefixes?.includes(router.pathname) ||
      (item.query && router.asPath.includes(item.query)),
  );

  const currentUrl = router.asPath.slice(1).split("?")[0];

  function cleanURL(currentURL: string): { formatted: string } {
    const parts = currentURL.split("/");

    const capitalizeAndClean = (str: string) => {
      return str
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(" ");
    };

    if (parts.length === 1) {
      return { formatted: capitalizeAndClean(parts[0]) };
    } else if (parts.length === 2) {
      if (!isNaN(Number(parts[1]))) {
        return { formatted: `${capitalizeAndClean(parts[0])} (${parts[1]})` };
      } else {
        return {
          formatted: `${capitalizeAndClean(parts[0])} ${capitalizeAndClean(
            parts[1],
          )}`,
        };
      }
    } else {
      return { formatted: "Unknown URL format" };
    }
  }

  const menuText = (text: string) => {
    switch (text) {
      case "People & Organizations":
        return "Connections";
      case "Opportunities":
        return "Opportunities";
      case "Vertex Platform":
        return "VPCs";
      default:
        return text;
    }
  };

  return (
    <>
      <div className="flex h-full md:hidden">
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute -right-[13px] top-[30px] flex h-7 w-7 flex-col items-center justify-center rounded-full bg-white drop-shadow-lg">
                    <ChevronLeftIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={() => setSidebarOpen(false)}
                      aria-hidden="true"
                    />
                  </div>
                </Transition.Child>
                <MobileSidebar closeSidebar={() => setSidebarOpen(false)} />
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
          </Dialog>
        </Transition>

        <div className="fixed inset-y-0 left-0 right-0 flex h-16 flex-1 flex-col overflow-y-auto overflow-x-hidden md:flex-row">
          <div className="sticky top-0 z-top bg-gray-100 md:hidden">
            <div className="flex h-14 items-center justify-between bg-white pl-4 text-blue-900">
              <div
                className={`flex flex-row items-center space-x-2 ${
                  openQuickSearch ? "invisible" : "visible"
                }`}
              >
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-md text-blue-900 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon
                    className="h-6 w-6 text-blue-900"
                    aria-hidden="true"
                  />
                </button>
                <button type="button" onClick={() => router.back()}>
                  <ArrowSmallLeftIcon className="h-6 w-6 text-blue-900" />
                </button>
                <div
                  className={`flex flex-col text-sm text-blue-900 ${
                    openQuickSearch ? "invisible" : "visible"
                  }`}
                >
                  {cleanURL(currentUrl).formatted}
                </div>
              </div>
              <div
                className={`flex flex-row items-center space-x-2 pr-4 ${
                  openQuickSearch ? "absolute right-0 z-[101]" : ""
                }`}
              >
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenCreate(false);
                      setOpenQuickSearch(!openQuickSearch);
                    }}
                  >
                    {!openQuickSearch ? (
                      <MagnifyingGlassIcon className="h-5 w-5 text-blue-900" />
                    ) : (
                      <XMarkIcon className="h-5 w-5 text-blue-900" />
                    )}
                  </button>
                </div>
                <div>
                  <button
                    id="create-dropdown-menu-mobile-plus-button"
                    type="button"
                    onClick={() => {
                      setOpenQuickSearch(false);
                      setOpenCreate(!openCreate);
                    }}
                  >
                    <PlusIcon className="h-5 w-5 text-blue-900" />
                  </button>
                </div>
              </div>
              <div
                className={`absolute top-0 right-0 left-0 z-top flex h-full w-full items-center pl-6 pr-6 duration-300 lg:invisible ${
                  openQuickSearch === true
                    ? "visible translate-x-0"
                    : "invisible translate-x-full"
                }`}
              >
                {openQuickSearch && <QuickSearch isMobile={true} />}
              </div>
              <CreateDropDownMenu
                showInHeader={true}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex min-h-screen flex-col">
        {/* Header */}
        <header className="flex h-16 items-center justify-between bg-white px-4">
          <div className="flex items-center gap-2">
            <div className="hidden text-blue-600 md:block">
              <img
                src="/labs-icon-blue-900-transparent.png"
                alt="Labs logo"
                className="h-[30px] w-[55px] cursor-pointer"
                onClick={() =>
                  router.push(
                    envString === "vvseai" || envString === "vvhc"
                      ? "/dealflow"
                      : "/",
                  )
                }
              />
            </div>
          </div>
          {/* Header top items */}
          <div className="mt-2 flex w-full items-center">
            <TopNavigationBar />
          </div>
        </header>

        <div className="flex flex-row">
          {/* Sidebar */}
          <SideNavigationBar
            updatedNavigation={updatedNavigation}
            current={current}
            menuText={menuText}
          />
          {/* Main content area */}
          <main
            className="show-scrollbar max-h-[calc(100vh-4rem)] flex-1 overflow-x-hidden rounded-tl-xl bg-slate-100 p-6"
            style={{ height: "100vh" }}
          >
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default Navigation;
