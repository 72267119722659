import {
  ArchiveBoxIcon,
  ClipboardIcon,
  FireIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import DropDownMenu from "./CreateDropDownMenu";
import ActivityIcon from "./Icons/ActivityIcon";
import { Llama } from "./Llama";
import ProfileMenu from "./ProfileMenu";
import QuickSearch from "./QuickSearch";

const Profile = () => {
  return <ProfileMenu navOpen={true} />;
};

const Tooltip = ({ value }: { value: string }) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className="tooltip invisible absolute left-0 top-9 z-top inline-block w-max rounded-lg bg-gray-900 py-1 px-2 text-3xs font-medium text-white shadow-sm group-hover:visible"
    >
      {value}
      <div className="tooltip-arrow" data-popper-arrow></div>
    </div>
  );
};

const TopNavigationBar = () => {
  const router = useRouter();
  const showAskVal = !router.asPath.includes("/askval");
  return (
    <div className="mb-2 ml-6 hidden w-full flex-row justify-end space-x-1 md:flex md:space-x-4">
      <QuickSearch />
      <DropDownMenu />
      <div className="hidden md:flex">
        <div
          id="ask-val-desktop-button"
          className={`group relative flex h-8 w-8 cursor-pointer flex-col items-center justify-center duration-300 hover:rounded-full hover:bg-blue-100 hover:text-blue-900 ${
            !showAskVal && "opacity-50"
          }`}
          onClick={() => {
            if (showAskVal) {
              window.sessionStorage.setItem("showValStatus", "true");
              window.dispatchEvent(new Event("storage"));
            }
          }}
        >
          <Llama customClass={"w-9 h-8"} color="rgb(30 58 138 / 1)" />
          <Tooltip value="Ask Val" />
        </div>
        <Link href={`/collab`}>
          <div className="group relative flex h-8 w-8 flex-col items-center justify-center duration-300 hover:rounded-full hover:bg-blue-100 hover:text-blue-900">
            <ArchiveBoxIcon className="relative h-6 w-6 stroke-1 text-blue-900" />
            <Tooltip value="Collaboration" />
          </div>
        </Link>
        <Link href={`/tasks`}>
          <div className="group relative flex h-8 w-8 flex-col items-center justify-center duration-300 hover:rounded-full hover:bg-blue-100 hover:text-blue-900">
            <ClipboardIcon className="relative h-6 w-6 stroke-1 text-blue-900" />
            <Tooltip value="Tasks" />
          </div>
        </Link>
        <Link href={`/activity_logs`}>
          <div className="group relative flex h-8 w-8 flex-col items-center justify-center duration-300 hover:rounded-full hover:bg-blue-100 hover:text-blue-900">
            <ActivityIcon className="h-6 w-6 fill-blue-900 stroke-1" />
            <Tooltip value="Activity log" />
          </div>
        </Link>
        <Link href={`/help`}>
          <div className="group relative flex h-8 w-8 flex-col items-center justify-center duration-300 hover:rounded-full hover:bg-blue-100 hover:text-blue-900">
            <QuestionMarkCircleIcon className="h-6 w-6 stroke-1 text-blue-900" />
            <Tooltip value="Help" />
          </div>
        </Link>
        <Link href={`/changelog`}>
          <div className="group relative flex h-8 w-8 flex-col items-center justify-center duration-300 hover:rounded-full hover:bg-blue-100 hover:text-blue-900">
            <FireIcon className="h-6 w-6 stroke-1 text-blue-900" />
            <Tooltip value="Change Log" />
          </div>
        </Link>
        <div className="mx-1.5">
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default TopNavigationBar;
