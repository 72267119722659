import { useField } from "formik";
import TechnologiesSelect from "../Select/TechnologiesSelect";
import { classNames } from "../utils";

type FormTechnologiesSelectFieldProps = {
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
};
const FormTechnologiesSelectField = ({
  name,
  required,
  label,
  errors,
  touched,
}: FormTechnologiesSelectFieldProps) => {
  const [input, meta, helpers] = useField(name);

  const { value, checked, onBlur } = input;
  const { setValue } = helpers;

  return (
    <div className="grid w-full md:w-11/12">
      <label className="text-xs font-semibold" htmlFor={name}>
        {label}
      </label>
      <div
        className={classNames(
          "border-1 relative h-fit w-full items-center rounded-md border",
          touched && errors ? "border-red-500" : "border-blue-900",
        )}
      >
        <TechnologiesSelect
          id={name}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          isMulti
        />
      </div>

      <div
        className={classNames(
          touched && errors ? "absolute -bottom-4 ml-2 text-xs" : "hidden",
        )}
      >
        {errors}
      </div>
    </div>
  );
};

export default FormTechnologiesSelectField;
