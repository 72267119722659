import { Popover, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import useSWR from "swr";
import { getter } from "../../api";
import { SearchResultOrganization } from "../../api/types";
import Avatar from "../Avatar";

type OrganizationDropdownProps = {
  organizationsSelected: SearchResultOrganization[];
  setOrganizationsSelected: Dispatch<
    SetStateAction<SearchResultOrganization[]>
  >;
};

export const OrganizationDropdown = ({
  organizationsSelected,
  setOrganizationsSelected,
}: OrganizationDropdownProps) => {
  const [orgQuery, setOrgQuery] = useState<string>("");
  const [organizationData, setOrganizationData] = useState<
    SearchResultOrganization[] | undefined
  >([]);
  const { data, mutate } = useSWR<SearchResultOrganization[]>(
    `/api/people_map/search/organization?q=${orgQuery}&limit=50&indexed=true`,
    getter,
  );

  useEffect(() => {
    document.addEventListener("UPDATE-WEBSITES", () => mutate());

    return () => {
      window.removeEventListener("UPDATE-WEBSITES", () => mutate());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data && orgQuery.trim().length > 0) {
      setOrganizationData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgQuery]);

  useEffect(() => {
    if (data && data.length > 0) {
      if (orgQuery.length > 0) {
        setOrganizationData(data);
      } else {
        let orgData =
          data?.filter(
            (org) =>
              organizationsSelected.filter(
                (orgSelected) => org.id === orgSelected.id,
              ).length === 0,
          ) ?? [];

        setOrganizationData([...organizationsSelected, ...orgData]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, orgQuery, organizationsSelected]);

  let paddingTop = 0;
  if (organizationData) {
    paddingTop =
      organizationData.length === 0
        ? -104
        : organizationData.length > 8
          ? -362
          : orgQuery.length > 0
            ? -organizationData.length * 36 - 93
            : -organizationData.length * 36 - 63;
  }

  return organizationData ? (
    <div className="relative ml-2 rounded-md bg-slate-100 px-2 py-1 text-2xs font-semibold text-gray-900">
      <Popover key={`prompt`}>
        {({ open, close }) => {
          return (
            <>
              <Popover.Button className={`text-2xs`}>
                <div className="flex flex-row items-center space-x-2">
                  {organizationsSelected.length > 0 && (
                    <div className="mr-1 h-1.5 w-1.5 rounded-full bg-blue-900" />
                  )}
                  Select Websites
                  <ChevronRightIcon className="ml-2 h-3 w-3" />
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={`absolute left-0 z-top w-52 rounded-lg border border-gray-200 bg-white p-2 2xs:right-0 2xs:left-auto`}
                  style={{
                    top: paddingTop,
                  }}
                >
                  <div className="mb-1 flex w-full flex-col items-center justify-between gap-y-1">
                    <div className="relative w-full">
                      <input
                        value={orgQuery}
                        onChange={(event) => setOrgQuery(event.target.value)}
                        className="h-7 w-full rounded border border-gray-200 px-2 text-xs font-light hover:border-blue-900 focus:outline-none"
                        placeholder={`Search Organization`}
                      />
                      {orgQuery.trim().length > 0 && (
                        <XMarkIcon
                          className="absolute right-1.5 top-1.5 h-4 w-4 cursor-pointer"
                          onClick={() => setOrgQuery("")}
                        />
                      )}
                    </div>
                  </div>
                  <div className="h-fit max-h-[305px] overflow-y-auto font-normal">
                    {orgQuery.length > 0 &&
                      organizationsSelected.length > 0 && (
                        <div className="p-2 italic">
                          <b className="mr-1">{`(${organizationsSelected.length})`}</b>
                          item(s) selected
                        </div>
                      )}
                    {organizationData && organizationData.length > 0 ? (
                      organizationData.map(
                        (organization: SearchResultOrganization) => (
                          <div
                            key={organization.id}
                            className={`mb-0.5 flex cursor-pointer flex-row items-center justify-between rounded py-1 px-1 text-xs ${
                              organizationsSelected.filter(
                                (org) => org.id === organization.id,
                              ).length > 0 && "bg-gray-50"
                            }`}
                            onClick={() => {
                              if (
                                organizationsSelected.filter(
                                  (org) => org.id === organization.id,
                                ).length > 0
                              ) {
                                setOrganizationsSelected(
                                  organizationsSelected.filter(
                                    (org) => org.id !== organization.id,
                                  ),
                                );
                              } else {
                                setOrganizationsSelected([
                                  ...organizationsSelected,
                                  organization,
                                ]);
                              }
                            }}
                          >
                            <div className="flex flex-row items-center gap-x-2">
                              <Avatar
                                name={organization.name}
                                size={28}
                                url={organization.logo_url}
                                className="shadow-sm"
                              />
                              <div className="line-clamp-1">
                                {organization.name}
                              </div>
                            </div>
                            {organizationsSelected.filter(
                              (org) => org.id === organization.id,
                            ).length > 0 && (
                              <div className="h-5 w-5">
                                <CheckIcon className="h-5 w-5 text-blue-900" />
                              </div>
                            )}
                          </div>
                        ),
                      )
                    ) : (
                      <div className="h7 items-center p-1 font-light">
                        No Organization Found
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </div>
  ) : (
    <></>
  );
};
