import { LicenseManager } from "ag-grid-enterprise";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import AuthProvider from "../components/AuthProvider";
import Breakpoints from "../components/Breakpoints";
import BrowserTitle from "../components/BrowserTitle";
import LayoutWithHeader from "../components/Layout";
import { AttachmentPreview } from "../components/PdfPreview";
import "../styles/globals.css";

LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY || "");

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    TimeAgo.setDefaultLocale(en.locale);
    TimeAgo.addLocale(en);
  }, []);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient && router.asPath.includes("/attachmentPreview") ? (
    <AttachmentPreview />
  ) : (
    <>
      <Head>
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
          crossOrigin="anonymous"
        />
      </Head>
      <BrowserTitle />
      <AuthProvider>
        <LayoutWithHeader>
          <Component {...pageProps} />
          {process.env.NODE_ENV === "development" && <Breakpoints />}
        </LayoutWithHeader>
      </AuthProvider>
    </>
  );
}

export default MyApp;
