import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { downloadAttachment, getBrowserName } from "./Utils/fileUtils";

const SET_SHOW_DOWNLOAD_BUTTON_DELAY = 800;

export const AttachmentPreview = () => {
  const router = useRouter();
  const { url, fileId, fileName } = router.query;
  const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const browserName = getBrowserName();
    if (browserName !== "safari") {
      setTimeout(() => {
        setShowDownloadButton(true);
      }, SET_SHOW_DOWNLOAD_BUTTON_DELAY);
    }
  }, []);

  const downloadFile = () => {
    downloadAttachment({
      fileId: parseInt(fileId as string),
      fileName: fileName as string,
    });
  };

  return (
    <div className="relative h-full w-full">
      <iframe
        ref={frameRef}
        id={"myIframe"}
        className="h-full w-full"
        src={url as string}
      />
      <div
        className={`absolute top-[10px] right-[86px] flex h-[35px] w-[35px] flex-col items-center justify-center ${
          showDownloadButton ? "bg-neutral-700" : "opacity-0"
        }`}
      ></div>
      <button
        className={`absolute top-[10px] right-[86px] flex h-[35px] w-[35px] flex-col items-center justify-center ${
          showDownloadButton
            ? "bg-neutral-700 text-white hover:rounded-full hover:bg-neutral-600"
            : "bg-[transparent] opacity-0"
        }`}
        onClick={() => downloadFile()}
      >
        <ArrowDownTrayIcon className="h-5 w-5" />
      </button>
    </div>
  );
};
