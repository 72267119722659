import { useEffect, useState } from "react";
import { getOpportunityTypes } from "../../api";
import { OpportunityType, Success } from "../../api/types";
import { isValidJson } from "../Utils/commons";

// This module implements a module-level cache for opportunity types.
// opportunityDataCache will be initiated upon loading of the module and shared across all components that use this hook.

export const useOpportunity = () => {
  const [types, setTypes] = useState<OpportunityType[]>([]);

  useEffect(() => {
    const opportunityDataCacheString =
      sessionStorage.getItem("opportunityTypes");
    if (opportunityDataCacheString && isValidJson(opportunityDataCacheString)) {
      setTypes(JSON.parse(opportunityDataCacheString));
    } else {
      getOpportunityTypes().then((data: OpportunityType[]) => {
        if (data) {
          setTypes(data);
          sessionStorage.setItem("opportunityTypes", JSON.stringify(data));
        }
      });
    }
  }, []);

  return { types };
};
